<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row>
          <a-col :md="4" :sm="24">
            <a-form-item
              label="日期"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-date-picker v-model="queryParams.queryDate" />
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="服务部门">
              <a-select
                :default-value="0"
                v-model="queryParams.deptTypeId"
                style="width: 180px"
              >
                <a-select-option
                  v-for="d in deptGroup"
                  :key="d.deptTypeId"
                  :value="d.deptTypeId"
                  >{{ d.deptTypeName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="部门" v-if="queryParams.deptTypeId==9">
              <a-select
                :default-value="0"
                v-model="queryParams.deptId"
                style="width: 180px"
                @change="fetchPositionAccList"
              >
                <a-select-option
                  v-for="d in deptList"
                  :key="d.deptId"
                  :value="d.deptId"
                  >{{ d.deptName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="职位" v-if="queryParams.deptTypeId==9">
              <a-select
                :default-value="0"
                v-model="queryParams.deptPositionId"
                style="width: 180px"
              >
                <a-select-option
                  v-for="d in positionList"
                  :key="d.deptPositionId"
                  :value="d.deptPositionId"
                  >{{ d.deptPositionName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item
              label="状态"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="选择状态"
                v-model="queryParams.queryStatus"
                style="width: 100%"
              >
                <a-select-option
                  v-for="s in status"
                  :key="s.keyy"
                  :value="s.keyy"
                  >{{ s.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-upload> </a-upload>
      </div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.userId"
        :dataSource="dataSource"
        :pagination="false"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 900 }"
      >
      </a-table>
    </div>
  </a-card>
</template>
<script>
import RangeDate from "@/components/datetime/RangeDate";
import { mapState } from "vuex";
import moment from "moment";
import { uniqueByKey } from "@/utils/common.js";

export default {
  name: "sign",
  components: { RangeDate },
  data() {
    return {
      deptTreeData: [],
      deptGroup: [
        { deptTypeId: 0, deptTypeName: "全部" },
        { deptTypeId: 9, deptTypeName: "伊顿电力设备有限公司" },
      ],
      advanced: false,
      dataSource: [],
      currentUser: [],
      deptList: [
        { deptId: 0, deptName: "全部" },
      ],
      positionList: [
        { deptPositionId: 0, deptPositionName: "全部" },
      ],
      queryParams: {
        queryDate: moment(new Date(), "YYYY-MM-DD"),
        deptTypeId: 0,
        deptId: 0,
        deptPositionId: 0,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      status: (state) => state.dict.dicts.user_sign_status,
    }),
    columns() {
      return [
        {
          title: "员工编号",
          dataIndex: "empno",
          width: 150,
        },
        {
          title: "用户名",
          dataIndex: "username",
          width: 150,
        },
        {
          title: "部门",
          dataIndex: "deptName",
          width: 200,
        },
        {
          title: "职位",
          dataIndex: "deptPositionName",
          width: 200,
        },
        {
          title: "状态",
          dataIndex: "signStatus",
          width: 200,
          customRender: (text, row, index) => {
            for (let _index in this.status) {
              let key = this.status[_index].keyy;
              if (row.userSign.status === key) {
                let val = this.status[_index].valuee;
                let color = this.status[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return row.userSign.status;
          },
        },
        {
          title: "打卡时间",
          dataIndex: "signTime",
          width: 200,
          customRender: (text, row, index) => {
            return row.userSign.signTime == undefined
              ? ""
              : moment(row.userSign.signTime).format("HH:mm:ss");
          },
        },
        {
          title: "打卡地址",
          dataIndex: "address",
          width: 200,
          customRender: (text, row, index) => {
            const province =
              row.userSign.province == undefined ? "" : row.userSign.province;
            const city =
              row.userSign.city == undefined ? "" : row.userSign.city;
            const area =
              row.userSign.area == undefined ? "" : row.userSign.area;
            return province + " " + city + " " + area;
          },
        },
      ];
    },
  },
  mounted() {
    this.fetch();
    this.loadCurrentUser();
  },
  methods: {
    moment,
    search() {
      this.selectData({
        ...this.queryParams,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      params.queryDate = moment(params.queryDate).format("YYYY-MM-DD");
      this.$get("user-sign", {
        ...params,
      }).then((r) => {
        let data = r.data;
        this.loading = false;
        this.dataSource = data.data;
      });
    },
    fetchPositionAccList(val) {
      this.$get(`dept-position/V1?deptId=`+val).then((p)=>{
          this.positionList = p.data;
        })
    },
    selectData(params = {}) {
      this.loading = true;
      params.queryDate = moment(params.queryDate).format("YYYY-MM-DD");
      this.$get("user-sign", {
        ...params,
      }).then((r) => {
        let data = r.data;
        this.loading = false;
        this.dataSource = data.data;
      });
    },
    loadCurrentUser() {
      this.$get("/screen/datav/current-users").then((r) => {
        this.currentUser = r.data.data || [];
        // 伊顿内部人员
        const inside = this.currentUser.filter((item) => item.deptTypeId == 9);
        const insideUniqueDept = uniqueByKey(inside, "deptId");
        insideUniqueDept.forEach((item, index, arr) => {
          this.deptList.push({ deptId: item.deptId, deptName: item.deptName });
        });
        const insideUniquePosition = uniqueByKey(inside, "deptPositionId");
        insideUniquePosition.forEach((item, index, arr) => {
          this.positionList.push({
            deptPositionId: item.deptPositionId,
            deptPositionName: item.deptPositionName,
          });
        });

        // 外部顾问
        const outside = this.currentUser.filter((item) => item.deptTypeId != 9);
        const outsideUnique = uniqueByKey(outside, "deptTypeId");
        outsideUnique.forEach((item, index, arr) => {
          this.deptGroup.push({
            deptTypeId: item.deptTypeId,
            deptTypeName: item.deptName,
          });
        });
      });
    },
    handleTableChange() {},
    reset() {
      // 重置查询参数
      this.queryParams.deptTypeId = 0;
      this.queryParams.deptId = 0;
      this.queryParams.deptPositionId = 0;
      delete this.queryParams.queryStatus;
      this.fetch();
    },
  },
};
</script>
<style lang="less" scoped>
.test {
  color: #42b984;
  font-size: 1.1rem;
  font-weight: 600;
}
</style>